import { inject } from '@angular/core';
import { CountryService } from '@core/country/country.service';
import { NavigationService } from 'app/core/navigation/navigation.service';
import { forkJoin } from 'rxjs';

export const initialDataResolver = () => {
  const navigationService = inject(NavigationService);
  // const messagesService = inject(MessagesService);
  // const notificationsService = inject(NotificationsService);
  // const quickChatService = inject(QuickChatService);
  // const shortcutsService = inject(ShortcutsService);
  const countrieService = inject(CountryService);

  // Fork join multiple API endpoint calls to wait all of them to finish
  return forkJoin([
    navigationService.get(),
    countrieService.findAll$({ skip: 0, take: 1000 }),
    // messagesService.getAll(),
    // notificationsService.getAll(),
    // quickChatService.getChats(),
    // shortcutsService.getAll(),
  ]);
};
