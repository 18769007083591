{
  "name": "tx-development",
  "version": "v1.250319f",
  "description": "TX Base Project",
  "author": "https://targx.com",
  "license": "https://themeforest.net/licenses/standard",
  "private": true,
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "build": "ng build",
    "build:dev": "ng build --configuration development",
    "watch": "ng build --watch --configuration development",
    "test": "ng test"
  },
  "dependencies": {
    "@angular/animations": "18.0.1",
    "@angular/cdk": "18.0.1",
    "@angular/common": "18.0.1",
    "@angular/compiler": "18.0.1",
    "@angular/core": "18.0.1",
    "@angular/forms": "18.0.1",
    "@angular/material": "18.0.1",
    "@angular/material-luxon-adapter": "18.0.1",
    "@angular/platform-browser": "18.0.1",
    "@angular/platform-browser-dynamic": "18.0.1",
    "@angular/router": "18.0.1",
    "@ngneat/transloco": "6.0.4",
    "apexcharts": "3.49.1",
    "crypto-js": "4.2.0",
    "highlight.js": "11.9.0",
    "lodash-es": "4.17.21",
    "luxon": "3.4.4",
    "ng-apexcharts": "1.10.0",
    "ngx-quill": "26.0.1",
    "perfect-scrollbar": "1.5.5",
    "quill": "2.0.2",
    "rxjs": "7.8.1",
    "tslib": "2.6.2",
    "zone.js": "0.14.6"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "18.0.2",
    "@angular/cli": "18.0.2",
    "@angular/compiler-cli": "18.0.1",
    "@tailwindcss/typography": "0.5.13",
    "@types/chroma-js": "2.4.4",
    "@types/crypto-js": "4.2.2",
    "@types/highlight.js": "10.1.0",
    "@types/jasmine": "5.1.4",
    "@types/lodash": "4.17.4",
    "@types/lodash-es": "4.17.12",
    "@types/luxon": "3.4.2",
    "autoprefixer": "10.4.19",
    "gts": "^5.3.1",
    "chroma-js": "2.4.2",
    "jasmine-core": "5.1.2",
    "karma": "6.4.3",
    "karma-chrome-launcher": "3.2.0",
    "karma-coverage": "2.2.1",
    "karma-jasmine": "5.1.0",
    "karma-jasmine-html-reporter": "2.1.0",
    "lodash": "4.17.21",
    "postcss": "8.4.38",
    "prettier": "3.3.0",
    "prettier-plugin-organize-imports": "3.2.4",
    "prettier-plugin-tailwindcss": "0.6.1",
    "tailwindcss": "3.4.3",
    "typescript": "5.4.5"
  },
  "packageManager": "yarn@1.22.21+sha1.1959a18351b811cdeedbd484a8f86c3cc3bbaf72"
}