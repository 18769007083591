import { Route } from '@angular/router';
import { initialDataResolver } from 'app/app.resolvers';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [

  // Redirect empty path to '/example'
  { path: '', pathMatch: 'full', redirectTo: 'leads' },
  { path: 'signed-in-redirect', pathMatch: 'full', redirectTo: 'leads' },

  // Auth routes for guests
  {
    path: '',
    canActivate: [NoAuthGuard],
    canActivateChild: [NoAuthGuard],
    component: LayoutComponent,
    data: {
      layout: 'empty',
      scheme: 'dark'
    },
    children: [
      { path: 'forgot-password', loadChildren: () => import('@modules/auth/forgot-password/forgot-password.routes') },
      { path: 'reset-password', loadChildren: () => import('@modules/auth/reset-password/reset-password.routes') },
      { path: 'sign-in', loadChildren: () => import('@modules/auth/sign-in/sign-in.routes') },
      {
        path: 'activate',
        loadChildren: () => import('@modules/auth/activation/activation.routes'),
      },
    ]
  },

  // Auth routes for authenticated users
  {
    path: '',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    component: LayoutComponent,
    data: {
      layout: 'empty'
    },
    children: [
      { path: 'sign-out', loadChildren: () => import('app/modules/auth/sign-out/sign-out.routes') },
      { path: 'unlock-session', loadChildren: () => import('app/modules/auth/unlock-session/unlock-session.routes') }
    ]
  },

  // Landing routes
  {
    path: '',
    component: LayoutComponent,
    data: {
      layout: 'empty'
    },
    children: [
      { path: 'home', loadChildren: () => import('app/modules/landing/home/home.routes') },
    ]
  },

  // Admin routes
  {
    path: '',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    component: LayoutComponent,
    resolve: {
      initialData: initialDataResolver
    },
    children: [
      { path: 'example', loadChildren: () => import('app/modules/admin/example/example.routes') },
      { path: 'profile', loadChildren: () => import('@modules/admin/settings/settings.routes') },
      {
        path: 'inbox',
        loadChildren: () => import('@modules/admin/mailbox/mailbox.routes'),
      },
      {
        path: 'partners',
        loadChildren: () => import('@modules/admin/partners/partners.routes'),
      },
      {
        path: 'clients',
        loadChildren: () => import('@modules/admin/clients/clients.routes'),
      },
      {
        path: 'leads',
        loadChildren: () => import('@modules/admin/leads/leads.routes'),
      },
      {
        path: 'requests',
        loadChildren: () => import('@modules/admin/requests/requests.routes'),
      },
      {
        path: 'sales',
        loadChildren: () => import('@modules/admin/sales/sales.routes'),
      },
      {
        path: 'users',
        loadChildren: () => import('@modules/admin/users/users.routes'),
      }
    ]
  }
];
