<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<!-- Navigation -->
<fuse-vertical-navigation class="dark bg-crm-black-500 print:hidden" [mode]="isScreenSmall ? 'over' : 'side'"
  [name]="'mainNavigation'" [navigation]="navigation.items" [opened]="!isScreenSmall"
  [appearance]="navigationAppearance">
  <!-- Navigation header hook -->
  <ng-container fuseVerticalNavigationContentHeader>
    <div class="flex w-full items-center p-4 pl-6">
      <!-- Logo -->
      <a class="flex items-center justify-center cursor-pointer" [routerLink]="['/']"
        routerLinkActive="router-link-active">
        <img class="w-8" src="images/horus/horus_logo.png" />
      </a>
      <!-- Components -->
      <div class="ml-auto flex items-center" *ngIf="navigationAppearance === 'default'">
        <!-- <notifications></notifications> -->
        <user [showAvatar]="false"></user>
      </div>
    </div>

  </ng-container>
  <!-- Navigation footer hook -->
  <!-- Navigation footer hook -->
  <ng-container fuseVerticalNavigationFooter>
    <!-- User -->
    <div class="flex w-full items-center justify-end px-6 py-8">
      <a href="https://3decide.com?utm_source=crm_horus" target="_blank" class="flec flex-col mr-auto"
        *ngIf="navigationAppearance === 'default'">
        <small class="text-[8px] text-[#6B6B6B]">Engineered by</small>
        <img src="/images/horus/3decide-logo-crm.svg" />
      </a>
      <!-- Navigation appearance toggle button -->
      <button class="hidden md:inline-flex" mat-icon-button (click)="toggleNavigationAppearance()">
        <mat-icon [svgIcon]="'heroicons_outline:arrows-right-left'" class="text-crm-icon-500"></mat-icon>
      </button>
    </div>
  </ng-container>
</fuse-vertical-navigation>

<!-- Wrapper -->
<div class="flex w-full min-w-0 flex-auto flex-col">
  <!-- Header -->
  <!-- <div
    class="bg-card relative z-49 flex h-16 w-full flex-0 items-center px-4 shadow dark:border-b dark:bg-transparent dark:shadow-none md:px-6 print:hidden hidden">
    <button mat-icon-button (click)="toggleNavigation('mainNavigation')">
      <mat-icon [svgIcon]="'heroicons_outline:bars-3'"></mat-icon>
    </button>
    <div class="ml-auto flex items-center space-x-0.5 pl-2 sm:space-x-2">
      <languages></languages>
      <fuse-fullscreen class="hidden md:block"></fuse-fullscreen>
      <search [appearance]="'bar'"></search>
      <shortcuts></shortcuts>
      <messages></messages>
      <button class="lg:hidden" mat-icon-button (click)="quickChat.toggle()">
        <mat-icon [svgIcon]="'heroicons_outline:chat-bubble-left-right'"></mat-icon>
      </button>
    </div>
  </div> -->

  <!-- Content -->
  <div class="flex flex-auto flex-col">
    <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
    @if (true) {
    <router-outlet></router-outlet>
    }
  </div>

  <!-- Footer -->
  <!--<div class="relative flex flex-0 items-center justify-start w-full h-14 px-4 md:px-6 z-49 border-t bg-card dark:bg-transparent print:hidden">
        <span class="font-medium text-secondary">Fuse &copy; {{currentYear}}</span>
    </div>-->
</div>

<!-- Quick chat 
<quick-chat #quickChat="quickChat"></quick-chat>
-->
