import { inject, Injectable } from '@angular/core';
import { UserService } from '@core/user/user.service';
import { ApiService } from '@targx/services/api/api.service';
import { Navigation } from 'app/core/navigation/navigation.types';
import { sortBy } from 'lodash-es';
import { Observable, ReplaySubject, tap } from 'rxjs';
@Injectable({ providedIn: 'root' })
export class NavigationService {
  private _httpClient = inject(ApiService);
  private _navigation: ReplaySubject<Navigation> =
    new ReplaySubject<Navigation>(1);

  readonly userService = inject(UserService);

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Getter for navigation
   */
  get navigation$(): Observable<Navigation> {
    return this._navigation.asObservable();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Get all navigation data
   */
  get(): Observable<Navigation> {
    return this._httpClient.post<Navigation>('/v1/menus/find-all').pipe(
      tap((navigation) => {
        if (this.userService._currentUser?.menus?.length) {
          navigation.items = [];

          this.userService._currentUser.menus.filter((menu) => menu.isActive).forEach((menu: any) => {
            if (!navigation.items.find((item: any) => item.id === menu.id)) {
              navigation.items.push({
                ...menu,
                title: menu.name,
                subtitle: menu.description,
                disabled: menu.isDisabled
              });
            }
          });
        }



        navigation.items = sortBy(navigation.items.map((item: any) => ({
          ...item,
          title: item.name,
          subtitle: item.description
        })), 'order');

        const allTopItems = sortBy(navigation.items.filter((item: any) => !item.parentId), 'order');
        allTopItems.forEach((topItem: any) => {
          topItem.children = sortBy(navigation.items.filter((item: any) => item.parentId === topItem.id), 'order');
        });
        navigation.items = allTopItems;
        this._navigation.next(navigation);
      })
    );
  }
  // -----------------------------------------------------------------------------------------------------
  __get(): Observable<any> {

    return this.userService.user$.pipe(
      tap((user) => {
        console.log("🚀 ~ NavigationService ~ map ~ user:", user)
        const navigation: Navigation = { items: [] };

        if (!user) {
          return null;
        }

        user.menus.forEach((menu: any) => {
          if (!navigation.items.find((item: any) => item.id === menu.id)) {
            navigation.items.push({
              ...menu,
              title: menu.name,
              subtitle: menu.description
            });
          }
        });

        navigation.items = sortBy(navigation.items.map((item: any) => ({
          ...item,
          title: item.name,
          subtitle: item.description
        })), 'order');

        const allTopItems = sortBy(navigation.items.filter((item: any) => !item.parentId), 'order');
        allTopItems.forEach((topItem: any) => {
          topItem.children = sortBy(navigation.items.filter((item: any) => item.parentId === topItem.id), 'order');
        });
        navigation.items = allTopItems;
        this._navigation.next(navigation);
      }))
  }

  // -----------------------------------------------------------------------------------------------------
  _getFromApi(): Observable<Navigation> {
    return this._httpClient.post<Navigation>('/v1/menus/find-all').pipe(
      tap((navigation) => {
        navigation.items = sortBy(navigation.items.map((item: any) => ({
          ...item,
          title: item.name,
          subtitle: item.description
        })), 'order');

        const allTopItems = sortBy(navigation.items.filter((item: any) => !item.parentId), 'order');
        allTopItems.forEach((topItem: any) => {
          topItem.children = sortBy(navigation.items.filter((item: any) => item.parentId === topItem.id), 'order');
        });
        navigation.items = allTopItems;
        this._navigation.next(navigation);
      })
    );

  }
}
